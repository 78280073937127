<template>
    <div>
        <div class="w-full mb-2 border-b py-2 h-screen-3/4" :id="`journal-text-${patient.id}`">
            <textarea id="selectable-text" v-html="journalTextHTML" class="w-full h-full"></textarea>
        </div>
        <div class="flex justify-end">
          <button data-clipboard-target="#selectable-text" class="copy-btn block bg-white text-grey-darkest hover:text-green-dark font-bold py-2 px-4 rounded border" type="button">
            <font-awesome-icon icon="copy" class="mx-2 align-middle"></font-awesome-icon>
            <span>Kopiera till urklipp</span>

          </button>
          <button class="bg-grey text-grey-darkest hover:bg-grey-dark font-bold py-2 px-4 rounded ml-2" type="button" @click="$emit('hideJournalText', $event)">
            Avbryt
          </button>
        </div>
        <div v-if="copySucceeded === true" class="my-1 mx-1 text-lg text-green-dark">Kopierat till urklipp!</div>
    </div>
</template>

<script>
import * as Clipboard from 'clipboard'

export default {
    name: 'JournalText',
    props: ['patient', 'userForms'],
    data () {
        return {
            clipboard: new Clipboard('.copy-btn'),
            copySucceeded: false,
            journalText: '',
            forms: {
                WHODAS36: false,
                WHODAS12: false,
                MADRS: false,
                DSM5: false,
                AUDIT: false,
                DUDIT: false,
                ASRS: false,
                LPFSBF: false,
                PID5: false,
                RAADS14: false,
                RAADS80: false,
                COREOM: false,
                CORE10: false,
                PCL5: false,
                PSYCHOTHERAPY: false,
            },
            uForms: {
                DIVA5: false
            },
            texts: {
                WHODAS36: 'WHODAS36Text',
                WHODAS12: 'WHODAS12Text',
                MADRS: 'MADRSText',
                DSM5: 'DSM5Text',
                AUDIT: 'AUDITText',
                DUDIT: 'DUDITText',
                ASRS: 'ASRSText',
                LPFSBF: 'LPFSBFText',
                PID5: 'PID5Text',
                RAADS14: 'RAADS14Text',
                RAADS80: 'RAADS80Text',
                COREOM: 'COREOMText',
                DIVA5: 'DIVA5Text',
                CORE10: 'CORE10Text',
                PCL5: 'PCL5Text',
                PSYCHOTHERAPY: 'PSYCHOTHERAPYText',
            },
            scores: {
                WHODAS36: null,
                WHODAS12: null,
                MADRS: null,
                DSM5: null,
                AUDIT: null,
                DUDIT: null,
                ASRS: null,
                LPFSBF: null,
                PID5: null,
                RAADS14: null,
                RAADS80: null,
                COREOM: null,
                CORE10: null,
                PCL5: null,
                PSYCHOTHERAPY: null,
            }

       }
    },
    computed: {
        journalTextHTML () {
            let text = '';

            let keys = Object.keys(this.forms).sort();

            for (let f of keys) {
                if (this.forms[f] === true) {
                    text += this[this.texts[f]] + '\r\n'
                }
            }

            for (let key in this.uForms) {
                if (this.uForms[key] === true) {
                    text += this[this.texts[key]] + "\r\n";
                }
            }

            return text
        },
        AUDITText() {
            let text = this.$t('journaltext.description.audit')
                .replace('%score%', this.scores.AUDIT)
                .replace('%score_snippet%', this.AUDITScoreSnippet)

            return text
        },
        AUDITScoreSnippet() {
            let score = +this.scores.AUDIT
            let snippet = ''

            if (this.patient.gender == 'male') {
                if (score >= 0 && score <= 8) {
                    snippet = this.$t('journaltext.scores.audit.1')
                } else if (score >= 9 && score <= 15) {
                    snippet = this.$t('journaltext.scores.audit.2')
                } else if (score >= 16 && score <= 20) {
                    snippet = this.$t('journaltext.scores.audit.3')
                } else if (score >= 21) {
                    snippet = this.$t('journaltext.scores.audit.4')
                }
            } else if (this.patient.gender == 'female') {
                if (score >= 0 && score <= 6) {
                    snippet = this.$t('journaltext.scores.audit.1')
                } else if (score >= 7 && score <= 15) {
                    snippet = this.$t('journaltext.scores.audit.2')
                } else if (score >= 16 && score <= 20) {
                    snippet = this.$t('journaltext.scores.audit.3')
                } else if (score >= 21) {
                    snippet = this.$t('journaltext.scores.audit.4')
                }
            }

            return snippet+'\r\n'
        },
        DUDITText() {
            let text = this.$t('journaltext.description.dudit')
                .replace('%score%', this.scores.DUDIT)
                .replace('%score_snippet%', this.DUDITScoreSnippet)
            return text
        },
        DUDITScoreSnippet() {
            let snippet = ''
            let score = this.scores.DUDIT

            if (this.patient.gender == 'male') {
                if (score == 0) {
                    snippet = this.$t('journaltext.scores.dudit.none');
                } else if (score >= 1 && score <= 5) {
                    snippet = this.$t('journaltext.scores.dudit.1');
                } else if (score >= 6 && score <= 24) {
                    snippet = this.$t('journaltext.scores.dudit.2');
                } else if (score >= 25) {
                    snippet = this.$t('journaltext.scores.dudit.3');
                }
            } else if (this.patient.gender == 'female') {
                if (score == 0) {
                    snippet = this.$t('journaltext.scores.dudit.none');
                } else if (score == 1) {
                    snippet = this.$t('journaltext.scores.dudit.1');
                } else if (score >= 2 && score <= 24) {
                    snippet = this.$t('journaltext.scores.dudit.2');
                } else if (score >= 25) {
                    snippet = this.$t('journaltext.scores.dudit.3');
                }
            }

            return snippet+'\r\n'
        },
        DSM5Text() {
            let text = this.$t('journaltext.description.dsm5')
                .replace('%score_snippet%', this.DSM5ScoreSnippet)

            return text
        },
        DSM5ScoreSnippet() {
            let snippet = ''
            let form = this.patient.form_responses.filter(f => f.code == 'DSM5')[0]

            for (let s in form.sections) {
                if (form.sections[s].score >= 60) {
                    snippet += `${form.sections[s].title} -  ${form.sections[s].score}\r\n`
                }
            }

            if (form.indicator_flags.hasOwnProperty('suicidalRisk') && form.indicator_flags.suicidalRisk === true) {
                snippet += this.$t('journaltext.scores.dsm5.suicidalRisk')+'\r\n'
            }

            if (snippet === '') {
                snippet = this.$t('journaltext.scores.dsm5.none')+'\r\n'
            }

            return snippet
        },
        WHODAS36Text() {
            let text = this.$t('journaltext.description.whodas36')
                .replace('%score%', this.scores.WHODAS36)
                .replace('%score_snippet%', this.WHODAS36ScoreSnippet)
                .replace('%domain_snippet%', this.WHODAS36DomainSnippet)

            return text
        },
        WHODAS36ScoreSnippet() {
            let score = this.scores.WHODAS36
            let snippet = ''

            if (score < 60) {
                snippet = this.$t('journaltext.scores.whodas36.1')
            } else if (score >= 60) {
                snippet = this.$t('journaltext.scores.whodas36.2')
            }
            //  else if (score >= 41) {
            //     snippet = this.$t('journaltext.scores.whodas36.3')
            // }

            return snippet
        },
        WHODAS36DomainSnippet() {
            let form = this.patient.form_responses.filter(f => f.code == 'WHODAS36')[0]
            let flags = form.indicator_flags

            let domain1 = flags.hasOwnProperty('domain_scores') && flags.domain_scores.hasOwnProperty('1') && flags.domain_scores['1'].flag == true ? flags.domain_scores['1'] : false;
            let domain2 = flags.hasOwnProperty('domain_scores') && flags.domain_scores.hasOwnProperty('2') && flags.domain_scores['2'].flag == true ? flags.domain_scores['2'] : false;
            let domain3 = flags.hasOwnProperty('domain_scores') && flags.domain_scores.hasOwnProperty('3') && flags.domain_scores['3'].flag == true ? flags.domain_scores['3'] : false;
            let domain4 = flags.hasOwnProperty('domain_scores') && flags.domain_scores.hasOwnProperty('4') && flags.domain_scores['4'].flag == true ? flags.domain_scores['4'] : false;
            let domain5 = flags.hasOwnProperty('domain_scores') && flags.domain_scores.hasOwnProperty('5') && flags.domain_scores['5'].flag == true ? flags.domain_scores['5'] : false;
            let domain5_household = flags.hasOwnProperty('domain_scores') && flags.domain_scores.hasOwnProperty('5_household') && flags.domain_scores['5_household'].flag == true ? flags.domain_scores['5_household'] : false;
            let domain5_work = flags.hasOwnProperty('domain_scores') && flags.domain_scores.hasOwnProperty('5_work') && flags.domain_scores['5_work'].flag == true ? flags.domain_scores['5_work'] : false;
            let domain6 = flags.hasOwnProperty('domain_scores') && flags.domain_scores.hasOwnProperty('6') && flags.domain_scores['6'].flag == true ? flags.domain_scores['6'] : false;

            if (!domain1 && !domain2 && !domain3 && !domain4 && !domain5 && !domain6 && !domain5_work && !domain5_household) {
                return this.$t('journaltext.scores.whodas36.domain.none')+`\r\n`
            }

            let snippet = this.$t('journaltext.scores.whodas36.domain.ingress')+`\r\n`

            let snippets = [];
            if (domain1 && domain1.flag) {
                snippets.push([domain1.score, `${this.$t('journaltext.scores.whodas36.domain.1').replace("%domain_score%", domain1.score)} \r\n\r\n`]);
            }
            if (domain2 && domain2.flag) {
                snippets.push([domain2.score, `${this.$t('journaltext.scores.whodas36.domain.2').replace("%domain_score%", domain2.score)} \r\n\r\n`]);
            }
            if (domain3 && domain3.flag) {
                snippets.push([domain3.score, `${this.$t('journaltext.scores.whodas36.domain.3').replace("%domain_score%", domain3.score)} \r\n\r\n`]);
            }
            if (domain4 && domain4.flag) {
                snippets.push([domain4.score, `${this.$t('journaltext.scores.whodas36.domain.4').replace("%domain_score%", domain4.score)} \r\n\r\n`]);
            }
            if (domain5_work && domain5_work.flag) {
                snippets.push([domain5_work.score, `${this.$t('journaltext.scores.whodas36.domain.5_work').replace("%domain_score%", domain5_work.score)} \r\n\r\n`]);
            }
            if (domain5_household && domain5_household.flag) {
                snippets.push([domain5_household.score, `${this.$t('journaltext.scores.whodas36.domain.5_household').replace("%domain_score%", domain5_household.score)} \r\n\r\n`]);
            }
            if (domain5 && domain5.flag) {
                snippets.push([domain5.score, `${this.$t('journaltext.scores.whodas36.domain.5').replace("%domain_score%", domain5.score)} \r\n\r\n`]);
            }
            if (domain6 && domain6.flag) {
                snippets.push([domain6.score, `${this.$t('journaltext.scores.whodas36.domain.6').replace("%domain_score%", domain6.score)} \r\n\r\n`]);
            }

            snippets.sort((a, b) => b[0] - a[0])
            
            snippets.forEach(s => snippet += s[1]);

            return snippet
        },
        MADRSText() {
            let text = this.$t('journaltext.description.madrs')
                .replace('%score%', this.scores.MADRS)
                .replace('%score_snippet%', this.MADRSScoreSnippet)
                .replace('%snippet_extra%', this.MADRSSuicidalRisk)

            return text
        },
        MADRSScoreSnippet () {
            let t = this.scores.MADRS
            let snippet = ''

            if (t >= 0 && t <= 12) {
                snippet = this.$t('journaltext.scores.madrs.1')+'\r\n'
            } else if (t >= 13 && t <= 19) {
                snippet = this.$t('journaltext.scores.madrs.2')+'\r\n'
            } else if (t >= 20 && t <= 34) {
                snippet = this.$t('journaltext.scores.madrs.3')+'\r\n'
            } else if (t >= 35) {
                snippet = this.$t('journaltext.scores.madrs.4')+'\r\n'
            }

            let form = this.patient.form_responses.filter(f => f.code == 'MADRS')[0]
            if (form.indicator_flags.hasOwnProperty('suicidalRisk') && form.indicator_flags.suicidalRisk === true) {
                snippet += this.$t('journaltext.scores.madrs.suicidalRisk')+'\r\n'
            }

            return snippet
        },
        MADRSSuicidalRisk () {
            if (this.item9score == 6) {
                return this.$t('journaltext.scores.madrs.suicidalRisk')+'\r\n'
            }

            return ''
        },
        ASRSText() {
            let form = this.patient.form_responses.filter(f => f.code == 'ASRS')[0]

            let inadvertency = form.named_scores.inadvertency;
            let hyperactivity = form.named_scores.hyperactivity;

            let text = this.$t('journaltext.description.asrs')
                .replace('%inadvertency_score%', inadvertency)
                .replace('%hyperactivity_score%', hyperactivity)
                .replace('%result_snippet%', this.ASRSScoreSnippet);

            text += "\r\n";

            return text
        },
        ASRSScoreSnippet () {
            let snippet = '';
            let form = this.patient.form_responses.filter(f => f.code == 'ASRS')[0]

            let inadvertency = form.named_scores.inadvertency;
            let hyperactivity = form.named_scores.hyperactivity;

            if (form.indicator_flags.hasOwnProperty('childSymptoms') && form.indicator_flags['childSymptoms'] === true && (inadvertency >= 5 || hyperactivity >= 5)) {
                snippet += this.$t('journaltext.scores.asrs.criteriaFulfilled');
            } else {
                snippet += this.$t('journaltext.scores.asrs.criteriaNotFulfilled');
            }

            return snippet
        },
        LPFSBFText() {
            let text = this.$t('journaltext.description.lpfsbf')
                .replace('%score_snippet%', this.LPFSBFScoreSnippet)

            return text
        },
        LPFSBFScoreSnippet () {
            let snippet = '';
            let form = this.patient.form_responses.filter(f => f.code == 'LPFSBF')[0]

            let identity = form.named_scores.identity;
            let selfdirection = form.named_scores.selfdirection;
            let empathy = form.named_scores.empathy;
            let intimacy = form.named_scores.intimacy;
            let totalscore = this.scores['LPFSBF'];

            let internalScore = (identity + selfdirection) / 2;
            let interpersonalScore = (empathy + intimacy) / 2;

            internalScore = Math.round(internalScore * 100) / 100;
            interpersonalScore = Math.round(interpersonalScore * 100) / 100;

            if (totalscore >= 65) {
                snippet += this.$t('journaltext.scores.lpfsbf.subScoreAboveCutoff') + '\r\n\r\n';
            } else {
                snippet += this.$t('journaltext.scores.lpfsbf.subScoreBelowCutoff') + '\r\n\r\n';
            }

            if (internalScore >= 1.5) {
                snippet += this.$t('journaltext.scores.lpfsbf.internal.aboveCutoff').replace('%internal_score%', internalScore) + '\r\n';
            } else {
                snippet += this.$t('journaltext.scores.lpfsbf.internal.belowCutoff').replace('%internal_score%', internalScore) + '\r\n';
            }
            snippet += this.$t('journaltext.scores.lpfsbf.internal.subElementText')
                .replace('%identity_score%', form.named_scores.identity)
                .replace('%selfdirection_score%', form.named_scores.selfdirection) + '\r\n\r\n';

            if (interpersonalScore >= 1.5) {
                snippet += this.$t('journaltext.scores.lpfsbf.interpersonal.aboveCutoff').replace('%interpersonal_score%', interpersonalScore) + '\r\n';
            } else {
                snippet += this.$t('journaltext.scores.lpfsbf.interpersonal.belowCutoff').replace('%interpersonal_score%', interpersonalScore) + '\r\n';
            }
            snippet += this.$t('journaltext.scores.lpfsbf.interpersonal.subElementText')
                .replace('%empathy_score%', form.named_scores.empathy)
                .replace('%intimacy_score%', form.named_scores.intimacy) + '\r\n\r\n';

            return snippet
        },
        PID5Text() {
            let text = '';
            let form = this.patient.form_responses.filter(f => f.code == 'PID5')[0];
            let tScoreDom = form.named_scores.t_score_norm_bach.domains;
            let tScoreProp = form.named_scores.t_score_norm_bach.properties;
            let domains = [];
            let properties = [];

            text += this.$t('journaltext.description.pid5.header') + '\r\n\r\n';

            if(tScoreDom.negativeaffect >= 65 || tScoreDom.negativeaffect <= 35){
                domains.push(this.$t('journaltext.description.pid5.negativeaffect')
                    .replace('%negative_affect%', tScoreDom.negativeaffect) + '\r\n\r\n');
            }

            if(tScoreDom.detachment >= 65 || tScoreDom.detachment <= 35){
                domains.push(this.$t('journaltext.description.pid5.detachment')
                    .replace('%detachment%', tScoreDom.detachment) + '\r\n\r\n');
            }

            if(tScoreDom.antagonism >= 65 || tScoreDom.antagonism <= 35){
                domains.push(this.$t('journaltext.description.pid5.antagonism')
                    .replace('%antagonism%', tScoreDom.antagonism) + '\r\n\r\n');
            }

            if(tScoreDom.disinhibition >= 65 || tScoreDom.disinhibition <= 35){
                domains.push(this.$t('journaltext.description.pid5.disinhibition')
                    .replace('%disinhibition%', tScoreDom.disinhibition) + '\r\n\r\n');
            }

            if(tScoreDom.psychoticism >= 65 || tScoreDom.psychoticism <= 35){
                domains.push(this.$t('journaltext.description.pid5.psychoticism')
                    .replace('%psychoticism%', tScoreDom.psychoticism) + '\r\n\r\n');
            }

            if(domains.length > 0){
                text += this.$t('journaltext.description.pid5.domains') + '\r\n';
            }else{
                text += this.$t('journaltext.description.pid5.domains') + '\r\n';
                text += this.$t('journaltext.description.pid5.nothingabnormaldom') + '\r\n\r\n';
            }

            domains = domains.sort((a, b) => {
                const reg = /T{1} {1}={1} {1}(\d{1,})/;
                const aNum = reg.exec(a)[1];
                const bNum = reg.exec(b)[1];
                if(parseInt(aNum) > parseInt(bNum)){
                    return -1;
                }
                return 1;
            });

            domains.forEach((t) => {
                text += t;
            })

            if(tScoreProp.submissiveness >= 65 || tScoreProp.submissiveness <= 35){
                properties.push(this.$t('journaltext.description.pid5.submissiveness')
                    .replace('%submissiveness%', tScoreProp.submissiveness) + '\r\n\r\n');
            }

            if(tScoreProp.depressivity >= 65 || tScoreProp.depressivity <= 35){
                properties.push(this.$t('journaltext.description.pid5.depressivity')
                    .replace('%depressivity%', tScoreProp.depressivity) + '\r\n\r\n');
            }

            if(tScoreProp.separationinsecurity >= 65 || tScoreProp.separationinsecurity <= 35){
                properties.push(this.$t('journaltext.description.pid5.separationinsecurity')
                    .replace('%separationinsecurity%', tScoreProp.separationinsecurity) + '\r\n\r\n');
            }

            if(tScoreProp.anxiousness >= 65 || tScoreProp.anxiousness <= 35){
                properties.push(this.$t('journaltext.description.pid5.anxiousness')
                    .replace('%anxiousness%', tScoreProp.anxiousness) + '\r\n\r\n');
            }

            if(tScoreProp.emotionallability >= 65 || tScoreProp.emotionallability <= 35){
                properties.push(this.$t('journaltext.description.pid5.emotionallability')
                    .replace('%emotionallability%', tScoreProp.emotionallability) + '\r\n\r\n');
            }

            if(tScoreProp.suspiciousness >= 65 || tScoreProp.suspiciousness <= 35){
                properties.push(this.$t('journaltext.description.pid5.suspiciousness')
                    .replace('%suspiciousness%', tScoreProp.suspiciousness) + '\r\n\r\n');
            }

            if(tScoreProp.restrictedaffectivity >= 65 || tScoreProp.restrictedaffectivity <= 35){
                properties.push(this.$t('journaltext.description.pid5.restrictedaffectivity')
                    .replace('%restrictedaffectivity%', tScoreProp.restrictedaffectivity) + '\r\n\r\n');
            }

            if(tScoreProp.withdrawal >= 65 || tScoreProp.withdrawal <= 35){
                properties.push(this.$t('journaltext.description.pid5.withdrawal')
                    .replace('%withdrawal%', tScoreProp.withdrawal) + '\r\n\r\n');
            }

            if(tScoreProp.intimacyavoidance >= 65 || tScoreProp.intimacyavoidance <= 35){
                properties.push(this.$t('journaltext.description.pid5.intimacyavoidance')
                    .replace('%intimacyavoidance%', tScoreProp.intimacyavoidance) + '\r\n\r\n');
            }

            if(tScoreProp.anhedonia >= 65 || tScoreProp.anhedonia <= 35){
                properties.push(this.$t('journaltext.description.pid5.anhedonia')
                    .replace('%anhedonia%', tScoreProp.anhedonia) + '\r\n\r\n');
            }

            if(tScoreProp.manipulativeness >= 65 || tScoreProp.manipulativeness <= 35){
                properties.push(this.$t('journaltext.description.pid5.manipulativeness')
                    .replace('%manipulativeness%', tScoreProp.manipulativeness) + '\r\n\r\n');
            }

            if(tScoreProp.deceitfulness >= 65 || tScoreProp.deceitfulness <= 35){
                properties.push(this.$t('journaltext.description.pid5.deceitfulness')
                    .replace('%deceitfulness%', tScoreProp.deceitfulness) + '\r\n\r\n');
            }

            if(tScoreProp.hostility >= 65 || tScoreProp.hostility <= 35){
                properties.push(this.$t('journaltext.description.pid5.hostility')
                    .replace('%hostility%', tScoreProp.hostility) + '\r\n\r\n');
            }

            if(tScoreProp.callousness >= 65 || tScoreProp.callousness <= 35){
                properties.push(this.$t('journaltext.description.pid5.callousness')
                    .replace('%callousness%', tScoreProp.callousness) + '\r\n\r\n');
            }

            if(tScoreProp.attentionseeking >= 65 || tScoreProp.attentionseeking <= 35){
                properties.push(this.$t('journaltext.description.pid5.attentionseeking')
                    .replace('%attentionseeking%', tScoreProp.attentionseeking) + '\r\n\r\n');
            }

            if(tScoreProp.grandiosity >= 65 || tScoreProp.grandiosity <= 35){
                properties.push(this.$t('journaltext.description.pid5.grandiosity')
                    .replace('%grandiosity%', tScoreProp.grandiosity) + '\r\n\r\n');
            }

            if(tScoreProp.irresponsibility >= 65 || tScoreProp.irresponsibility <= 35){
                properties.push(this.$t('journaltext.description.pid5.irresponsibility')
                    .replace('%irresponsibility%', tScoreProp.irresponsibility) + '\r\n\r\n');
            }

            if(tScoreProp.implusivity >= 65 || tScoreProp.implusivity <= 35){
                properties.push(this.$t('journaltext.description.pid5.implusivity')
                    .replace('%implusivity%', tScoreProp.implusivity) + '\r\n\r\n');
            }

            if(tScoreProp.risktaking >= 65 || tScoreProp.risktaking <= 35){
                properties.push(this.$t('journaltext.description.pid5.risktaking')
                    .replace('%risktaking%', tScoreProp.risktaking) + '\r\n\r\n');
            }

            if(tScoreProp.distractability >= 65 || tScoreProp.distractability <= 35){
                properties.push(this.$t('journaltext.description.pid5.distractability')
                    .replace('%distractability%', tScoreProp.distractability) + '\r\n\r\n');
            }

            if(tScoreProp.perseveration >= 65 || tScoreProp.perseveration <= 35){
                properties.push(this.$t('journaltext.description.pid5.perseveration')
                    .replace('%perseveration%', tScoreProp.perseveration) + '\r\n\r\n');
            }

            if(tScoreProp.rigidperfectionism >= 65 || tScoreProp.rigidperfectionism <= 35){
                properties.push(this.$t('journaltext.description.pid5.rigidperfectionism')
                    .replace('%rigidperfectionism%', tScoreProp.rigidperfectionism) + '\r\n\r\n');
            }

            if(tScoreProp.eccentricity >= 65 || tScoreProp.eccentricity <= 35){
                properties.push(this.$t('journaltext.description.pid5.eccentricity')
                    .replace('%eccentricity%', tScoreProp.eccentricity) + '\r\n\r\n');
            }

            if(tScoreProp.perceptualdysregulation >= 65 || tScoreProp.perceptualdysregulation <= 35){
                properties.push(this.$t('journaltext.description.pid5.perceptualdysregulation')
                    .replace('%perceptualdysregulation%', tScoreProp.perceptualdysregulation) + '\r\n\r\n');
            }

            if(tScoreProp.usualbeliefsandexperiences >= 65 || tScoreProp.usualbeliefsandexperiences <= 35){
                properties.push(this.$t('journaltext.description.pid5.usualbeliefsandexperiences')
                    .replace('%usualbeliefsandexperiences%', tScoreProp.usualbeliefsandexperiences) + '\r\n\r\n');
            }

            if(properties.length > 0){
                text += this.$t('journaltext.description.pid5.properties') + '\r\n';
            }else{
                text += this.$t('journaltext.description.pid5.properties') + '\r\n';
                text += this.$t('journaltext.description.pid5.nothingabnormalprop') + '\r\n\r\n';
            }

            properties = properties.sort((a, b) => {
                const reg = /T{1} {1}={1} {1}(\d{1,})/;
                const aNum = reg.exec(a)[1];
                const bNum = reg.exec(b)[1];
                if(parseInt(aNum) > parseInt(bNum)){
                    return -1;
                }
                return 1;
            });

            properties.forEach((t) => {
                text += t;
            });

            text += this.$t('journaltext.description.pid5.validityscales') + '\r\n';

            text += this.$t('journaltext.description.pid5.ors')
                .replace("%ors%", form.named_scores.over_reporting_scale) + '\r\n';
            text += this.$t('journaltext.description.pid5.ris')
                .replace("%ris%", form.named_scores.response_inconsistency_scale) + '\r\n';

            let valid = 0;
            if (form.named_scores.over_reporting_scale >= 3 || form.named_scores.response_inconsistency_scale >= 18){
                valid = 0;
            }else{
                valid = 1;
            }
            
            text += this.$t('journaltext.description.pid5.valid.' + valid) + '\r\n\r\n';

            text += this.$t('journaltext.description.pid5.screening') + '\r\n';
            
            text += this.$t('journaltext.description.pid5.borderline')
                .replace("%borderline%", form.named_scores.borderline_algorithm) + '\r\n';
            
            let validS = 0;
            if (form.named_scores.borderline_algorithm >= 11){
                validS = 0;
            }else{
                validS = 1;
            }
            
            text += this.$t('journaltext.description.pid5.validScreening.' + validS) + '\r\n\r\n';

            return text;
        },
        WHODAS12Text() {
            let text = "";
            let score = this.scores["WHODAS12"];

            text += this.$t("journaltext.description.whodas12").replace("%score%", score).replace("%score_snippet%", this.whodas12Snippet);

            return text;
        },
        whodas12Snippet() {
            let text = "";
            let score = this.scores["WHODAS12"];
            if(score <= 65){
                text += this.$t('journaltext.scores.whodas12.1');
            }else if(score > 65){
                text += this.$t('journaltext.scores.whodas12.2');
            }
            return text;
        },
        RAADS14Text()
        {
            let text = '';

            text += this.$t('journaltext.description.raads14').replace('%score%', this.scores.RAADS14);

            if (this.scores.RAADS14 < 15) {
                text += this.$t('journaltext.scores.raads14.below') + '\r\n\r\n'
            } else {
                text += this.$t('journaltext.scores.raads14.above') + '\r\n\r\n'
            }

            return text;
        },
        RAADS80Text()
        {
            let text = '';

            let form = this.patient.form_responses.filter(f => f.code == 'RAADS80')[0];

            text += this.$t('journaltext.description.raads80').replace('%score%', form.score) + '\r\n\r\n';

            if (form.score >= 98) {
                text += this.$t('journaltext.scores.raads80.above') + '\r\n\r\n';
            } else {
                text += this.$t('journaltext.scores.raads80.below') + '\r\n\r\n';
            }

            text += this.$t('journaltext.scores.raads80.namedScores') + '\r\n';
            text += this.$t('journaltext.scores.raads80.circumscribedInterests').replace("%circumscribed_score%", form.named_scores.circumscribed_interests) + '\r\n';
            text += this.$t('journaltext.scores.raads80.language').replace("%language_score%", form.named_scores.language) + '\r\n';
            text += this.$t('journaltext.scores.raads80.sensoryMotor').replace("%sensory_score%", form.named_scores.sensory_motor) + '\r\n';
            text += this.$t('journaltext.scores.raads80.socialRelatedness').replace("%social_score%", form.named_scores.social_relatedness) + '\r\n';

            return text + "\r\n\r\n";
        },
        COREOMText() {
            let text = '';

            let form = this.patient.form_responses.filter(f => f.code == 'COREOM')[0];

            text += this.$t('journaltext.description.coreom');

            let totalText;

            if(form.score <= 5){
                totalText = this.$t('journaltext.scores.coreom.healthy');
            }else if(form.score <= 9){
                totalText = this.$t('journaltext.scores.coreom.low');
            }else if(form.score <= 14){
                totalText = this.$t('journaltext.scores.coreom.mild');
            }else if(form.score <= 19){
                totalText = this.$t('journaltext.scores.coreom.moderate');
            }else if(form.score <= 24){
                totalText = this.$t('journaltext.scores.coreom.moderateSerious');
            }else if(form.score <= 40){
                totalText = this.$t('journaltext.scores.coreom.serious');
            }

            text += this.$t('journaltext.scores.coreom.total').replace('%total_score%', form.score).replace('%total_text%', totalText);

            let risk = form.named_scores.risk;

            let gender = this.patient.gender;

            text += this.$t('journaltext.scores.coreom.risk').replace('%risk_score%', risk).replace('%risk_status%', function() {
                if((risk >= 3 && gender == 'female') || (risk > 3 && gender == 'male')) {
                    return 'hög';
                } else {
                    return 'låg';
                }
            });

            return text;
        },
        CORE10Text() {
            let text = '';

            let form = this.patient.form_responses.filter(f => f.code == 'CORE10')[0];

            text += this.$t('journaltext.description.core10');

            let totalText;

            if(form.score <= 5){
                totalText = this.$t('journaltext.scores.core10.healthy');
            }else if(form.score <= 9){
                totalText = this.$t('journaltext.scores.core10.low');
            }else if(form.score <= 14){
                totalText = this.$t('journaltext.scores.core10.mild');
            }else if(form.score <= 19){
                totalText = this.$t('journaltext.scores.core10.moderate');
            }else if(form.score <= 24){
                totalText = this.$t('journaltext.scores.core10.moderateSerious');
            }else if(form.score <= 40){
                totalText = this.$t('journaltext.scores.core10.serious');
            }

            text += this.$t('journaltext.scores.core10.total').replace('%total_score%', form.score).replace('%total_text%', totalText);

            return text;
        },
        DIVA5Text() {
            let text = "";
            let data = this.userForms.DIVA5.responseData;

            text += this.$t("journaltext.description.diva5").replace("%interview_type%", this.$t("journaltext.scores.diva5.type." + data.Info.typ)).replace("%oupp_vuxen%", data.AForm.Summa.Adult.Summa).replace("%oupp_barn%", data.AForm.Summa.Child.Summa).replace("%hyper_vuxen%", data.HIForm.Summa.Adult.Summa).replace("%hyper_barn%", data.HIForm.Summa.Child.Summa).replace("%yes_no%", (!data.Bedomning.VuxenOupp && !data.Bedomning.VuxenHyper) ? "inte " : "") + "\r\n\r\n";

            if (!data.Bedomning.VuxenOupp && !data.Bedomning.VuxenHyper) {
                text += this.$t("journaltext.scores.diva5.adult") + "\r\n\r\n";
            } else {
                text += this.$t("journaltext.scores.diva5.lifelong").replace("%yes_no%", data.Bedomning.LivsLangtSymp ? "" : "inte ") + "\r\n\r\n";
                text += !data.Bedomning.LivsLangtSymp ? this.$t("journaltext.scores.diva5.development") + "\r\n\r\n" : "";

                if(data.Bedomning.LivsLangtSymp) {
                    let functions = data.DForm.D2.Adult;
                    let funcs = [];
                    for(let key in functions) {
                        if (typeof functions[key] === "object") {
                            if (functions[key].Symptom === true) {
                                funcs.push(this.$t("journaltext.scores.diva5.functions." + key));
                            }
                        }
                    }
                    text += this.$t("journaltext.scores.diva5.func").replace("%yes_no%", data.Bedomning.PsykStoV ? "" : "inte ").replace("%functions%", funcs.join(", ")) + "\r\n\r\n";
                    text += !data.Bedomning.PsykStoV ? this.$t("journaltext.scores.diva5.funcNo") + "\r\n\r\n" : "";
                    if(data.Bedomning.PsykStoV) {
                        text += this.$t("journaltext.scores.diva5.symp").replace("%yes_no%", data.Bedomning.PsykAnnat ? "" : "inte ") + "\r\n\r\n";
                        text += data.Bedomning.PsykAnnat ? this.$t("journaltext.scores.diva5.sympBetter").replace("%symp_better%", data.Bedomning.PsykAnnatMessage) + "\r\n\r\n" : "";
                    }
                }
            }


            return text;
        },
        PCL5Text() {
            let text = "";

            let form = this.patient.form_responses.filter(f => f.code == "PCL5")[0];

            let scoreText = "";
            if (form.score >= 31 && form.named_scores.provisional_ptsd === "Ja") {
                scoreText = this.$t('journaltext.scores.pcl5.provisional_yes');
            } else {
                scoreText = this.$t('journaltext.scores.pcl5.provisional_no');
            }

            text += this.$t("journaltext.description.pcl5").replace("%score_text%", scoreText) + "\r\n\r\n";
            text += this.$t("journaltext.scores.pcl5.total").replace("%total_score%", form.score) + "\r\n";
            text += this.$t("journaltext.scores.pcl5.cluster_b").replace("%score%", form.named_scores.cluster_b).replace("%answered%", this.getAnsweredQuestionsString(form.sections, [7, 8, 9, 10, 11])) + "\r\n";
            text += this.$t("journaltext.scores.pcl5.cluster_c").replace("%score%", form.named_scores.cluster_c).replace("%answered%", this.getAnsweredQuestionsString(form.sections, [12, 13])) + "\r\n";
            text += this.$t("journaltext.scores.pcl5.cluster_d").replace("%score%", form.named_scores.cluster_d).replace("%answered%", this.getAnsweredQuestionsString(form.sections, [14, 15, 16, 17, 18, 19, 20])) + "\r\n";
            text += this.$t("journaltext.scores.pcl5.cluster_e").replace("%score%", form.named_scores.cluster_e).replace("%answered%", this.getAnsweredQuestionsString(form.sections, [21, 22, 23, 24, 25, 26])) + "\r\n";

            return text;
        },
        PSYCHOTHERAPYText() {
            let text = "";
            let form = this.patient.form_responses.filter(f => f.code == "PSYCHOTHERAPY")[0];

            text += this.$t("journaltext.description.psychotherapy") + "\r\n";

            form.sections.forEach((section) => {
                section.questions.forEach((question) => {
                    text += question.title + "\r\n" + question.answer + "\r\n\r\n";
                });
            });

            return text;
        }

    },
    beforeMount () {
        this.enableFormTexts()
    },
    mounted () {
        this.clipboard.on('success', () => {
            this.copySucceeded = true
        })
        this.clipboard.on('error', () => {
            this.copySucceeded = false
            alert(this.$t('info.copyError'))
        })

    },
    methods: {
        enableFormTexts() {
            const forms = this.patient.form_responses.filter((f) => {
                return (
                    f.code == 'WHODAS36' ||
                    f.code == 'WHODAS12' ||
                    f.code == 'MADRS' ||
                    f.code == 'DSM5' ||
                    f.code == 'AUDIT' ||
                    f.code == 'DUDIT' ||
                    f.code == 'ASRS' ||
                    f.code == 'LPFSBF' ||
                    f.code == 'PID5' ||
                    f.code == 'RAADS14' ||
                    f.code == 'RAADS80' ||
                    f.code == 'COREOM' ||
                    f.code == 'CORE10' ||
                    f.code == 'PCL5' ||
                    f.code == 'PSYCHOTHERAPY'
                )
            })

            for (const key in this.userForms) {
                let uForm = this.userForms[key];
                this.uForms[uForm.code] = true;
            }

            for (var i = forms.length - 1; i >= 0; i--) {
                let form = forms[i]
                this.scores[form.code] = form.score
                this.forms[form.code] = true
            }

        },
        returnWhodasScoreSnippet(score)
        {
            if(score >= 50 && score <= 95){
                return this.$t("journaltext.scores.whodas36.irt.1");
            }else if(score > 95){
                return this.$t("journaltext.scores.whodas36.irt.2");
            }
        },
        // handleCopyStatus(status) {
        //   this.copySucceeded = status
        // },
        // copyText () {
        //     this.$copyText(this.getJournalText())
        //         .then(() => {
        //             this.copySucceeded = true
        //         })
        //         .catch(() => {
        //             this.copySucceeded = false
        //         })
        // },
        // getJournalText() {
        //     let text = `Utfall för självskattningsinstrument av ${this.patient.first_name} ${this.patient.last_name} följer nedan\r\r`

        //     const forms = this.patient.form_responses.filter((f) => {
        //         return (
        //         f.code == 'WHODAS36' ||
        //         f.code == 'MADRS' ||
        //         f.code == 'DSM5' ||
        //         f.code == 'AUDIT' ||
        //         f.code == 'DUDIT')
        //     }).sort((a, b) => b.code.localeCompare(a.code))


        //     for (var i = forms.length - 1; i >= 0; i--) {
        //         let form = forms[i]
        //         let formscore = `${form.code} (${localFormat(form.created)}): ${form.score}`
        //         if (form.sections.length > 1) {
        //             let sectionScores = form.sections.map(s => `${s.title}: ${s.score}`)
        //             formscore += ` (${sectionScores.join(', ')})`
        //         }
        //         text += `${formscore}\r\n`
        //     }

        //     return text
        // },
        getAnsweredQuestionsString(sections, questions)
        {
            let values = [];
            for (let x = 0; x < sections.length; x++) {
                let section = sections[x];
                for (let y = 0; y < section.questions.length; y++) {
                    let question = section.questions[y];
                    if (!(questions.indexOf(question.question_id) === -1)) {
                        if (question.answers.length === 1) {
                            values.push(question.answers[0].value);
                        }
                    }
                }
            }

            return values.toString();
        }
    }
}
</script>

